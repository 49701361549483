@use "sass:math";
@use "../../sass/vars/borders";
@use "../../sass/vars/color-palette";
@use "../../sass/vars/layout";
@use "../../sass/vars/typography";

.quantity-selector {
  p {
    margin-bottom: layout.$base-spacing;
  }
}

.quantity-choices-container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: layout.$base-spacing;

  li {
    flex: 1 1 45%;
  }

  [type="radio"] {
    margin-right: math.div(layout.$base-spacing, 4);
  }
}

.discount {
  background-color: color-palette.$green-200;
  border-radius: borders.$default-border-radius;
  display: inline-block;
  font-size: typography.$small-font-size;
  margin-left: math.div(layout.$base-spacing, 2);
  padding: math.div(layout.$base-spacing, 4);
}
