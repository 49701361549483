@use "./sass/vars/borders";
@use "./sass/vars/layout";

.configurator-container {
  display: flex;
  gap: layout.$base-spacing;
  margin: layout.$base-spacing;

  .configurator-main {
    flex: 1 1 75%;
  }

  .configurator-overview {
    border: borders.$thin-light-border;
    flex: 1 1 25%;
    padding: layout.$base-spacing;
  }
}
