@use "sass:math";
@use "../../sass/vars/color-palette";
@use "../../sass/vars/layout";

/* Turning of the rule here because it is already habdled in src/css/libs/reset.css  */
/* stylelint-disable a11y/media-prefers-reduced-motion */
.accordion-panel {
  max-height: 0;
  overflow: hidden;

  .notecard + .product-options-selector,
  .notecard + .quantity-selector-content-container {
    padding-top: 0;
  }
}

.show-panel {
  background-color: color-palette.$white;
  flex-wrap: wrap;
  margin: 0;
  max-height: 1000px;
  padding: math.div(layout.$base-spacing, 2);
  transition: max-height 0.5s ease;
}
