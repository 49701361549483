@use "sass:math";
@use "../../sass/vars/color-palette";
@use "../../sass/vars/borders";
@use "../../sass/vars/layout";
@use "../../sass/vars/typography";

.modal-overlay {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  @media #{layout.$mq-tablet-and-up} {
    align-items: center;
    display: flex;
    justify-content: center;
    top: 20px;
  }

  @media #{layout.$mq-large-desktop-and-up} {
    bottom: 0;
    position: fixed;
    top: 0;
  }
}

.design-modal {
  background-color: color-palette.$white;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 50%);
  height: max-content;
  padding: layout.$base-spacing;
  position: relative;

  @media #{layout.$mq-small-desktop-and-up} {
    max-width: 50vw;
  }

  .error {
    background: color-palette.$red-700;
    border-radius: 5px;
    color: color-palette.$red-100;
    margin-bottom: (layout.$base-spacing / 2);
    margin-top: (layout.$base-spacing / 2);
    padding: (layout.$base-spacing / 2) (layout.$base-spacing / 1.5);
  }

  form {
    max-width: 500px;
    padding: 20px;
  }

  fieldset {
    margin-bottom: layout.$base-spacing * 1.5;

    legend {
      font-size: typography.$base-font-size;
    }
  }

  label {
    display: block;
    margin-bottom: layout.$base-spacing / 4;
    margin-top: layout.$base-spacing / 2;
  }

  [type="text"] {
    min-width: 250px;
  }

  textarea {
    width: 100%;
  }

  .footnote,
  .input-details {
    font-size: typography.$super-small-font-size;
  }

  .input-details {
    margin-top: layout.$base-spacing / 4;
  }

  .footnote {
    margin-bottom: layout.$base-spacing / 4;
    margin-top: layout.$base-spacing / 2;
  }
}

button.close-modal,
.close-modal {
  background-color: transparent;
  min-width: initial;
  position: absolute;
  right: 0;
  top: 10px;

  &::before {
    background: transparent url("../../assets/close.svg") center center
      no-repeat;
    background-size: 24px;
    content: "";
    display: block;
    height: 24px;
    width: 24px;
  }
}
