@use "sass:math";
@use "../../sass/vars/color-palette";
@use "../../sass/vars/layout";

.color-selector {
  p {
    margin-bottom: layout.$base-spacing;
  }
}

.color-choices-container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: layout.$base-spacing;

  li {
    flex: 1 1 25%;
  }

  .option-container {
    align-items: center;
    border: 1px solid color-palette.$neutral-500;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: layout.$base-spacing;
  }

  [type="radio"] {
    margin-right: math.div(layout.$base-spacing, 4);
  }
}
