$neutral-100: #212121;
$neutral-150: #333;
$neutral-200: #969696;
$neutral-500: #ccc;
$neutral-600: #eaeaea;
$neutral-700: #f9f9f9;

$white: #fff;

$green-100: #598e2e;
$green-200: rgba(124, 194, 66, 1);

$black-100: #333738;

$orange-100: #f15822;
$orange-200: #ef7d01;

$blue-200: #048cd3;

$red-100: #931815;
$red-500: #de1c1d;
$red-700: #fab1b0;

$text-color: $neutral-100;
