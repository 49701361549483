.reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  /* !important required to ensure element is hidden when mixin is applied */
  position: absolute;
  width: 1px;
}
