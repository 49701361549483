@use "sass:math";

$browser-default-font-size: 16px;

$base-unit: 6px;
$base-spacing: $base-unit * 4;

$small-mobile-ends: math.div(479px, $browser-default-font-size) * 1em;
$mobile-starts: $small-mobile-ends + 0.001em;
$mobile-ends: math.div(767px, $browser-default-font-size) * 1em;

$tablet-starts: $mobile-ends + 0.001em;
$tablet-ends: math.div(1023px, $browser-default-font-size) * 1em;

$small-desktop-starts: $tablet-ends + 0.001em;
$small-desktop-ends: math.div(1199px, $browser-default-font-size) * 1em;

$mq-large-desktop-and-up: "all and (min-width: #{$small-desktop-ends})";
$mq-small-desktop-and-up: "all and (min-width: #{$small-desktop-starts})";
$mq-tablet-and-up: "all and (min-width: #{$tablet-starts})";
$mq-mobile-and-up: "all and (min-width: #{$mobile-starts})";

/* z-index scales */
$mid-layer: 9;
$top-layer: 99;
$bring-to-front: 999;
$send-to-back: -1;
