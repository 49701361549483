@use "sass:math";
@use "../../sass/vars/color-palette";
@use "../../sass/vars/layout";
@use "../../sass/vars/typography";

.accordion-heading {
  background-color: color-palette.$neutral-200;
  border: 0;
  color: color-palette.$white;
  cursor: pointer;
  display: flex;
  font-size: typography.$base-font-size;
  justify-content: space-between;
  padding: math.div(layout.$base-spacing, 2);
  text-align: left;
  width: 100%;

  &[aria-expanded="true"] {
    background-color: color-palette.$green-200;
  }
}
