@use "sass:math";

@use "../vars/color-palette" as *;
@use "../vars/layout" as *;

button,
.button {
  border: 0;
  cursor: pointer;
  min-width: 200px;
  padding: math.div($base-spacing, 4) math.div($base-spacing, 2);
}

.primary {
  background-color: $orange-100;
  color: $white;
  margin: math.div($base-spacing, 2) 0;

  &:hover,
  &:focus {
    background-color: $orange-200;
    color: $neutral-100;
  }
}
