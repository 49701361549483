@use "sass:math";
@use "../../sass/vars/layout";
@use "../../sass/vars/color-palette";

.summary {
  margin: math.div(layout.$base-spacing, 2);

  h3 {
    margin-bottom: math.div(layout.$base-spacing, 2);
  }
}

.summary-table {
  border-collapse: collapse;
  width: 100%;

  th {
    text-align: left;
  }

  th,
  td {
    border: 1px solid color-palette.$neutral-500;
    padding: math.div(layout.$base-spacing, 2);
  }
}
