@use "sass:math";
@use "../../sass/vars/borders";
@use "../../sass/vars/layout";

.configurator-overview {
  h3 {
    margin-bottom: layout.$base-spacing;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: borders.$thin-light-dotted-border;
      margin-bottom: layout.$base-spacing;
      padding-bottom: math.div(layout.$base-spacing, 2);
    }
  }
}
