@use "sass:math";
@use "../../sass/vars/borders.scss";
@use "../../sass/vars/color-palette.scss";
@use "../../sass/vars/layout.scss";

.card-selector {
  .choices-container {
    display: flex;
    flex-wrap: wrap;
    gap: layout.$base-spacing;
    margin-bottom: layout.$base-spacing;
  }

  .option-container {
    border: 1px solid color-palette.$neutral-200;
    border-radius: borders.$default-border-radius;
    flex: 0 1 15%;
    padding: layout.$base-spacing;
    text-align: center;

    &.selected {
      border-color: color-palette.$green-200;
    }

    .option-img {
      margin: math.div(layout.$base-spacing, 2) auto;
    }
  }
}
